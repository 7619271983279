import axios from 'axios';
import store from "@/store"
let $uStore = [];
try {
	$uStore = store.state;
} catch (e) {
}
// 创建axios实例
const service = axios.create({
  baseURL: 'https://home3.pg-slot-test.com/apis', // api的base_url
  timeout: 5000 // 请求超时时间
});
// https://home3.pg-slot-test.com/v3.home/bulletins
 
// 请求拦截器
service.interceptors.request.use(
  config => {
    // 可以在这里添加请求头等信息
    if ($uStore.vuex_token) {
      config.headers['Authorization'] = $uStore.vuex_token_head.token_head + ' ' + $uStore.vuex_token;
    }
	if(config.method=='get'){
		if(!config.isLOgin){
			store.commit('sLoading',true)
		}
	}else if(config.method=='post'){
		let data=(typeof config.data =='string'?JSON.parse(config.data):config.data);
		if(!data.isLOgin){
			store.commit('sLoading',true)
		}
	}
    return config;
  },
  error => {
    // 请求错误处理
    console.log(error); // for debug
    Promise.reject(error);
  }
);
 
// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做处理，例如只返回data部分
    const res = response.data;
	store.commit('sLoading',false)
	if(res.code==401){
		$uStore.commit('isRecord',true)
	}
    // if (res.code !== 20000) {
    //   Message({
    //     message: res.message,
    //     type: 'error',
    //     duration: 5 * 1000
    //   });
    //   // 50001: 非法的token; 50002: 其他客户端错误; 50003: 认证失败; 50004: 授权失败; 50005: 未找到用户
    //   if (res.code === 50001 || res.code === 50002 || res.code === 50003 || res.code === 50004 || res.code === 50005) {
    //     // 移除token
    //     resetToken();
    //     // 跳转到登录页面
    //     location.reload();
    //   }
    //   return Promise.reject('error');
    // } else {
    //   return res;
    // }
    return res;
  },
  error => {
	let errorRes=error.response;
			store.commit('sLoading',false)
	if(errorRes.status==401){

		store.commit('isRecord',true)
		store.commit('$uStore', {name:'vuex_token_head',value:''})
		store.commit('$uStore', {name:'vuex_token',value:{}})
	}
	// Promise.reject(error).then((e)=>{
	// })
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // });
    return Promise.reject(error);
  }
);


 
export default service;
