<template>
	<div class="login-register-from-space">
		<div class="X5eogfqvbxD_ckZVQSll">{{$t("Login._Suporte") }}</div>
		<div class="" style="margin-bottom: .2rem;position: relative;">
			<antInput left-icon="a" :modelValue="formConter.account" @focus="onFocus_c"
				@blur="blurisRememberPassword" @onInput="onInput" form-key='account' clear-trigger="always"
				maxlength="16" :placeholder="$t('Login._dengLuPa')" :class="{
				 	'input-active':isFocus
				 }">
				 
				<div slot="left" v-if="(!guoqu)||!typeInit1"  style="display: flex;align-items: center;">
					<svg-icon className="imageWrap"
						style="width: .26rem;height: .26rem;color: var(--theme-text-color-lighten);"
						icon-class="input_icon_zh--svgSprite:all" />
					<span class="ZEcfoKF3Q_zq5EqaOdUy" >
						<i v-if="!formConter.account">*</i>
					</span>
				</div>
				<div slot="left" v-else class="icontest"
					style="display: flex;align-items: center;color: var(--theme-text-color-lighten);">
					<img style="width: 0.36rem; height: 0.27rem;" src="./../../assets/icons/images/Brazil.png">
					<span style="padding-left: .1rem;padding-right: .4rem;">+55</span>
				</div>
			</antInput>
			<div class="ant-select-dropdow" v-if="isRememberPassword && rememberPasswordList.length"
				style="overflow: scroll;">
				<div class="Conter_arrConte" :class="{
					 active:item.account==formConter.account,
				 }" v-for="(item,index) in rememberPasswordList" v-if="item.account">
					<div style="display: flex;align-items: center;" @click="rememberPasswordFun(index)">
						<i class="" style="display: inline-flex; justify-content: center; align-items: center;"><svg
								width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"
								viewBox="0 0 35.999 36">
								<g id="comm_icon_clock" transform="translate(0.499 0.501)">
									<path id="Union_159" data-name="Union 159"
										d="M-5182.98-6307.534a18.078,18.078,0,0,1-5.724-3.871,18.109,18.109,0,0,1-3.873-5.724,17.7,17.7,0,0,1-1.423-6.981,17.881,17.881,0,0,1,1.405-6.978,17.957,17.957,0,0,1,3.844-5.724,17.858,17.858,0,0,1,12.749-5.3,17.861,17.861,0,0,1,12.751,5.3,17.957,17.957,0,0,1,3.844,5.724,17.882,17.882,0,0,1,1.406,6.978,17.739,17.739,0,0,1-1.423,6.981,18.108,18.108,0,0,1-3.872,5.724,18.089,18.089,0,0,1-5.724,3.871,17.711,17.711,0,0,1-6.981,1.424A17.706,17.706,0,0,1-5182.98-6307.534Zm-8.2-16.455A15.193,15.193,0,0,0-5176-6308.811a15.193,15.193,0,0,0,15.177-15.178A15.194,15.194,0,0,0-5176-6339.165,15.194,15.194,0,0,0-5191.176-6323.988Zm15.054,2.93a1.336,1.336,0,0,1-1.35-1.35v-11.667a1.337,1.337,0,0,1,1.35-1.352,1.337,1.337,0,0,1,1.353,1.352v10.2h10.072a1.368,1.368,0,0,1,1.35,1.471,1.334,1.334,0,0,1-1.35,1.35Z"
										transform="translate(5193.5 6341.609)"></path>
								</g>
							</svg></i>
						<span class="conter" style="padding-left: .1rem;">{{item.account}}</span>
					</div>
					<i class="tengAr" @click="rememberPasswordListFun(index,item.account)"
						style="display: inline-flex; justify-content: center; align-items: center;"><svg width="1em"
							height="1em" fill="currentColor" aria-hidden="true" focusable="false"
							viewBox="0 0 21.351 24">
							<g id="comm_icon_del" transform="translate(-1946.084 -41.758)">
								<path id="Path_503" data-name="Path 503"
									d="M1966.49,59.525c.1-.085.12-.114.141-.116,1.694-.211,1.694-.21,1.694,1.456q0,5.73,0,11.46c0,2.576-1.283,3.844-3.877,3.844-3.343,0-6.685.007-10.028,0a3.29,3.29,0,0,1-3.528-3.463c-.025-4.3-.007-8.6-.006-12.893,0-.1.019-.207.035-.376h1.8v.85q0,6.049,0,12.1c0,1.486.47,1.954,1.952,1.955q5.014,0,10.027,0c1.277,0,1.787-.492,1.789-1.77q.011-6.128,0-12.256Z"
									transform="translate(-2.844 -10.413)"></path>
								<path id="Path_504" data-name="Path 504"
									d="M1962.479,44.734h3.736c.623,0,1.234.078,1.22.89-.012.767-.567.919-1.217.918q-9.5-.005-19,0c-.632,0-1.139-.163-1.137-.9s.5-.917,1.135-.914c1.24,0,2.479,0,3.791,0,.019-.313.033-.567.05-.821a2.086,2.086,0,0,1,2.251-2.146c2.279-.016,4.558-.007,6.837,0a2.084,2.084,0,0,1,2.331,2.291C1962.484,44.236,1962.479,44.419,1962.479,44.734Zm-9.559-.065h7.689c.149-1.093.123-1.127-.851-1.128q-2.979,0-5.959,0C1952.811,43.541,1952.811,43.543,1952.92,44.669Z">
								</path>
								<path id="Path_505" data-name="Path 505"
									d="M1964.983,67.255c0-1.485,0-2.97,0-4.456,0-.552.143-1.01.74-1.162.641-.164,1.084.264,1.092,1.088.015,1.644.005,3.289.005,4.933,0,1.379.009,2.758,0,4.138-.007.786-.369,1.225-.969,1.185-.743-.05-.86-.587-.861-1.19Q1964.982,69.523,1964.983,67.255Z"
									transform="translate(-11.21 -11.772)"></path>
								<path id="Path_506" data-name="Path 506"
									d="M1977.18,67.343c0,1.484,0,2.968,0,4.453,0,.8-.333,1.236-.932,1.2-.745-.039-.857-.56-.856-1.176.007-3,0-5.99,0-8.985,0-.81.325-1.232.929-1.2.746.038.864.553.859,1.172C1977.173,64.321,1977.18,65.832,1977.18,67.343Z"
									transform="translate(-17.385 -11.792)"></path>
							</g>

						</svg></i>
				</div>
			</div>
		
		</div>
		<div class="ant-form-explain" v-if="fonData.account.value">{{fonData.account.value}}</div>
		<div class="" v-if="!isJNumber" >
			<ul class="HwvTcjBqOBJV3S7RoZ7g">
				<li :class="{
					SujLpXQmwzyb0cHe24WB:typeInit1
				}" 
				@click="typeInit1=true,typeInit2=false"
				class="PKcz5ZikL2DpbGOLGX5v "><span  class="s93aRfyanxVk3xwCEtng"><span
							class="__48IgiPyYEOTYz32Z7O">
							<van-checkbox v-model="typeInit1" shape="square" checked-color="var(--theme-primary-color)"
								icon-size=".32rem">
								<span class="chen_texbt" :style="{
									color:typeInit1?'var(--theme-primary-color)':''
								}">Este é o número do <br> celular</span>
							</van-checkbox>
							<!-- <i class="anticon"><i class=""
									style="display: inline-flex; justify-content: center; align-items: center;"><svg
										width="1em" height="1em" fill="currentColor" aria-hidden="true"
										focusable="false" viewBox="0 0 43.998 44.002">
										<path id="联合_669" data-name="联合 669"
											d="M22141.994,23716v-1H22143v1Zm-42.992,0v-1h.994v1Zm42.992-43v-1H22143v1Zm-42.992,0v-1h.994v1Z"
											transform="translate(-22099.002 -23671.998)" opacity="0.04"></path>
										<path id="密码"
											d="M3381.12,1299.991v-1.8a7.158,7.158,0,0,0-6.7-7.2,7.039,7.039,0,0,0-7.411,6.992v2h-.283a1.726,1.726,0,0,0-1.732,1.719v11.562a1.726,1.726,0,0,0,1.732,1.719H3381.4a1.725,1.725,0,0,0,1.732-1.719V1301.71a1.725,1.725,0,0,0-1.732-1.719Zm-11.758-1.833a4.7,4.7,0,0,1,9.406,0v1.833h-9.406Zm6.212,12.278a.493.493,0,0,1-.126.388.508.508,0,0,1-.376.167h-2.015a.507.507,0,0,1-.376-.167.493.493,0,0,1-.125-.388l.318-2.837a1.971,1.971,0,0,1-.826-1.608,2.016,2.016,0,0,1,4.031,0,1.97,1.97,0,0,1-.824,1.608Z"
											transform="translate(-3351.994 -1280.989)"></path>

									</svg></i></i> --></span></span></li>
				<li 
				:class="{
					SujLpXQmwzyb0cHe24WB:typeInit2
				}" 
				@click="typeInit2=true,typeInit1=false"
				class="PKcz5ZikL2DpbGOLGX5v"><span class="s93aRfyanxVk3xwCEtng"><span
							class="__48IgiPyYEOTYz32Z7O">
							<van-checkbox v-model="typeInit2" shape="square" checked-color="var(--theme-primary-color)"
								icon-size=".32rem">
								<span class="chen_texbt" :style="{
									color:typeInit2?'var(--theme-primary-color)':''
								}">Esta é a conta</span>
							</van-checkbox>
							</span></span></li>
			</ul>
		</div>
		<div class="" style="margin-bottom: .2rem;">
			<antInput left-icon="a" :modelValue="formConter.password" @focus="onFocus" @onInput="onInput"
				form-key='password' :type="fonData.password.type" :placeholder="$t('Login._Senha')" :class="{
				 	'input-active':isFocus
				 }">
				<div slot="left" style="display: flex;align-items: center;">
					<svg-icon className="imageWrap"
						style="width: .26rem;height: .26rem;color: var(--theme-text-color-lighten);"
						icon-class="input_icon_mm--svgSprite:all" />
					<span class="ZEcfoKF3Q_zq5EqaOdUy">
						<i v-if="!formConter.password">*</i>
					</span>
				</div>
				<!-- fonData.password.type -->
				<div slot="right-icon" @click="showHide('password')">
					<svg-icon className="right-icon"
						style="width: .26rem;height: .26rem;color: var(--theme-text-color-lighten);"
						:icon-class="fonData.password.type=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'" />
				</div>
			</antInput>
		</div>
		<div class="ant-form-explain" v-if="fonData.password.value">{{fonData.password.value}}</div>
		<div class="ant-form-explain" v-if="fonData.phone.value">{{fonData.phone.value}}</div>
		<div class="" style="margin-bottom: .2rem;">
			<van-checkbox v-model="checked" shape="square" checked-color="var(--theme-secondary-color-success)"
				icon-size=".32rem">
				<span class="chen_texbt">{{$t("Login._Lembre") }}</span>
			</van-checkbox>
		</div>
		<div class="" style="display: flex;align-items: center;padding-bottom: .2rem;">
			<button type="button" 
				style="border-radius: .14rem;"
			@click="registerUrl" class="GaL3XJonIwzK4ZeJyCyq">
				{{$t("Login._Login") }}
			</button>
		</div>
		<!-- 
		-->
		<ul
			style="display: flex;align-items: center;color:var(--theme-primary-color);font-size: .22rem;line-height: .26rem;text-align: center;">
			<li style="width: 33.3%;" @click="openUrlLofin('notice')">{{$t("Login._Suporteaocliente") }}</li>
			<li style="width: 33.3%;" @click="openUrlLofin('subgame',{
				id:'',
				category:'demo'
			})">{{$t("Login._JogarGrátis") }}</li>
			<li style="width: 33.3%;" @click="changePasswordFun">{{$t("Login._EsqueceuaSenha") }}</li>
		</ul>
	</div>
</template>
<!-- 

 -->
<script>
	function checkPasswordStrength(password) {
		let score = 0;
		if (password.length >=6) score++;
		if (password.length > 0) score++;
		if (password.length >= 6) {
			if (password.match(/[A-Z]/)) score++; // 大写字母
			if (password.match(/[a-z]/)) score++; // 小写字母
			if (password.match(/[0-9]/)) score++; // 数字
			if (password.match(/[\W_]/)) score++; // 特殊字符
		}
		if (password.length > 10) score++;
		if (password.length >=6) score++;
		console.log(score)
		switch (score) {
			case 0:
			case 1:
			case 2:
			case 3:
			case 4:
				return 1; // 弱
			case 5:
				return 2; // 中等
			case 6:
				return 3; // 强
			case 7:
			default:
				return 4; // 非常强
		}
	}
	import register from "./register.vue"
	import antInput from "./input.vue"
	import {
		login
	} from "@/apis/modules/login.js";
	import {
		Toast
	} from 'vant';
	export default {
		components: {
			antInput
		},
		props: {
			isRecord: {
				type: Boolean,
				default: true,
			},
			login: {
				type: Function,
				default: () => {},
			}
		},
		data() {
			return {
				checked: true,
				isFocus: false,
				typeInit1:true,
				typeInit2:false,
				formConter: {
					account: "",
					password: '',
					type:2,
				},
				value: '',
				fonData: {
					account: {
						placeholder: 'Por favor, insira Número do Celular/Conta',
						tis: 'Erro no formato da conta,4-16 caracteres, suporta inglês/números',
						value: ''
					},
					password: {
						placeholder: '6-16 dígitos, inglês/números/símbolos',
						tis: '6-16 caracteres, incluindo pelo menos duas /n letras/números/símbolos',
						value: '',
						type: 'password'
					},
					password_confirm: {
						placeholder: '6-16 caracteres, incluindo pelo menos duas letras/números/símbolos',
						tis: 'Senha incorreta, digite novamente!',
						value: '',
						type: 'password'
					},
					phone: {
						placeholder: 'Número do CelularCampo não pode estar vazio',
						tis: 'Número do CelularCampo não pode estar vazio',
						value: '',
						type: 'password'
					}
					// 
				},
				mimaQiangdu: -1,
				value1: 0,
				option1: [{
					text: 'BRL（BRL）',
					value: 0
				}],
				isJNumber: true,
				guoqu:false,
				text: '',
				rememberPasswordList: [],
				rememberPasswordC: [],
				isRememberPassword: false,
			}
		},
		created() {
			let rememberPassword = window.localStorage.getItem('rememberPassword');
			if (rememberPassword) {
				let aem = JSON.parse(rememberPassword);
				const uniqueArray = Array.from(aem.reduce((map, item) => {
					map.set(item.account, item);
					return map;
				}, new Map()).values());
				this.formConter={
					...aem[0]
				};
				this.rememberPasswordList = uniqueArray;
				this.rememberPasswordC = uniqueArray;
				window.localStorage.setItem('rememberPassword', JSON.stringify(uniqueArray))
			}
		},
		mounted() {

		},
		methods: {
			rememberPasswordListFun(index, account) {
				this.rememberPasswordC = this.rememberPasswordC.filter(item => account != item.account);
				this.rememberPasswordList = this.rememberPasswordList.filter(item => account != item.account);
				window.localStorage.setItem('rememberPassword', JSON.stringify(this.rememberPasswordList))
			},
			rememberPasswordFun(index) {
				this.formConter = {
					...this.rememberPasswordList[index]
				};
				this.rememberPasswordList=[this.formConter]
			},
			blurisRememberPassword() {
				setTimeout(() => {
					this.isRememberPassword = false
				})
			},
			openUrlLofin(name,quser) {
				this.openUrl(name,quser);
				this.$store.commit('isRecord')
			},
			registerUrl() {
				let {
					account,
					password
				} = this.formConter;
				let _istrue = false;
				if (!account || account.length < 4) {
					_istrue = true;
				}
				if (!password || password.length < 4) {
					_istrue = true;
				}
				if (_istrue) {
					for (let i in this.formConter) {
						this.onInput(this.formConter[i], i)
					}
					return false;
				}
				// 
				// this.formConter.type
				this.$emit('onLogin', this.formConter, this.rememberPasswordC)
			},
			showHide(e) {
				this.fonData[e].type = this.fonData[e].type == 'password' ? 'text' : 'password'
			},
			changePasswordFun() {
				this.$emit('changePasswordFun', true)
			},
			onFocus_c(e, formKey){
				if (!formKey) {
					return false
				}
				this.isRememberPassword=true;
				this.onFocus(e, formKey)
			},
			onFocus(e, formKey) {
				if (!formKey) {
					return false
				}
				console.log(formKey=='account')
				let formSo = this.formConter[formKey];
				let formValue = this.fonData[formKey];
				if (!formSo) {
					formValue.value = formValue.placeholder
				}
			},
			textHighlighting(searchText) {
				const filteredData = this.rememberPasswordC.filter((item) => {
					return item.account.includes(searchText)
				});
				this.rememberPasswordList = filteredData;
			},
			onInput(e, formKey) {
				if (formKey == 'account') {
					this.textHighlighting(e);
				}
				if (!formKey) {
					return false
				}
				this.formConter[formKey] = e;
				let formSo = this.formConter[formKey];
				let formValue = this.fonData[formKey];

				if (formSo) {
					let regex = /^[a-zA-Z0-9_]{4,16}$/;
					
					if (formKey == 'account' && !regex.test(e)) {
						formValue.value = formValue.tis;
					} else if (formKey == 'account' && regex.test(e)) {
						formValue.value = '';
					}
					if (formKey == 'account' && /^\d+$/.test(e)) {
						if (e.length<=10 && e.length >= 6) {
							this.isJNumber = false;
						} else {
							this.isJNumber = true;
						}
						if( e.length<=9 && e.length >= 6){
							this.guoqu=true;
							formValue.value ='O código de área +55 suporta 10-11 números de celular, digite novamente';
						}else if( e.length==12){
							this.guoqu=true;
							formValue.value = 'O código de área +55 suporta 10-11 números de celular, digite novamente';
						}else{
							formValue.value = '';
						}
						if(e.length >= 6 && e.length<=12){
							this.guoqu=true;
						}else{
							this.guoqu=false;
						}
					}
					
					if (formKey == 'password') {
						console.log(checkPasswordStrength(e),'checkPasswordStrength(e)')
						if (checkPasswordStrength(e) < 2) {
							formValue.value = formValue.tis;
						} else {
							formValue.value = '';
						}
					}
					if (formKey == 'password_confirm' && formSo) {
						if (formSo != this.formConter.password) {
							formValue.value = formValue.tis;
						} else if (formSo == this.formConter.password) {
							formValue.value = '';
						}

					}
					if (formKey == 'phone' && formSo) {
						formValue.value = '';
					}


					// formValue.value=formValue.tis;
				} else {
					formValue.value = formValue.placeholder
				}
				this.formConter = {
					...this.formConter
				}
			},
			onChange() {

			},
			isFun(e) {
				this.isFocus = e;
			},
		}
	}
</script>
<style>
	.HwvTcjBqOBJV3S7RoZ7g .van-checkbox__icon--checked .van-icon{
		color:  var(--theme-main-bg-color);
		
	}
	.HwvTcjBqOBJV3S7RoZ7g .van-checkbox__label{
		line-height: initial;
	}
	.login-register-from-space .van-search {
		display: flex;
		align-items: center;
		border-radius: .14rem;
		font-size: .22rem;
		height: .7rem;
		background-color: var(--theme-main-bg-color);
		border: 1px solid #d9d9d9;
		padding-right: .1rem;
		border-color: var(--theme-color-line);
	}

	.login-register-from-space .van-checkbox__icon .van-icon {
		border-radius: .06rem;
		border-color: var(--theme-color-line);
	}
	.login-register-from-space .s93aRfyanxVk3xwCEtng{
		display: flex;
		line-height: .32rem;
	}

	.login-register-from-space .van-ellipsis {
		position: relative;
	}

	.login-register-from-space .van-cell__title {
		position: relative;
		padding-left: 0.42rem;
	}

	.login-register-from-space .van-cell__title:before,
	.login-register-from-space .van-ellipsis:before {
		content: '';
		display: inline-block;
		position: relative;
		width: 0.36rem;
		height: 0.36rem;
		position: absolute;
		overflow: hidden;
		background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
		background-position: -8.58857rem -3.35571rem;
		background-size: 9.33429rem 9.10929rem;
	}

	.login-register-from-space .van-cell__title:before {
		left: 0;
		top: .2rem;
	}

	.van-field__body i {
		font-size: .35rem;
		color: var(--theme-text-color-lighten);
	}

	.login-register-from-space .van-search__content {
		background-color: initial !important;
	}

	.login-register-from-space .van-checkbox__label {
		display: flex;
		align-items: center;
	}

	.PKcz5ZikL2DpbGOLGX5v.SujLpXQmwzyb0cHe24WB {
		color: var(--theme-primary-color) !important;
	}


	.login-register-from-space .data-user-agreement {
		color: var(--theme-primary-color)
	}

	.login-register-from-space input::-webkit-input-placeholder {
		/* 使用webkit内核的浏览器 */
		color: var(--theme-text-color-placeholder) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}

	.login-register-from-space input:-moz-placeholder {
		/* Firefox版本4-18 */
		color: var(--theme-text-color-placeholder) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}

	.login-register-from-space input::-moz-placeholder {
		/* Firefox版本19+ */
		color: var(--theme-text-color-placeholder) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}

	.login-register-from-space input:-ms-input-placeholder {
		/* IE浏览器 */
		color: var(--theme-text-color-placeholder) !important;
		line-height: .3rem !important;
		font-size: .22rem !important;
		font-weight: 400 !important;
	}

	.van-field__body input {
		color: var(--theme-text-color-darken);
	}

	.input-active {
		/* var(--theme-primary-color); */
		box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
		border: .02rem solid var(--theme-filter-active-color) !important;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width {
		width: auto !important;
		max-width: initial !important;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-menu__bar {
		height: .7rem;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-menu__bar .van-dropdown-menu__item {
		border: .01rem solid var(--theme-color-line);
		background-color: var(--theme-main-bg-color);
		font-size: .2rem;
		height: .7rem !important;
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		border-radius: .1rem;
		line-height: .5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 11px;
		padding-right: .4rem !important;
		color: var(--theme-text-color-lighten) !important;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .addcinte {
		height: .7rem;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-menu__bar .van-ellipsis {
		color: var(--theme-text-color-darken);
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-popup,
	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-item {
		width: 100%;
	}

	.v0FZl0YCkb_Z5KOXk68M4._max-width .van-dropdown-item {
		top: .8rem !important;
	}
	.login-register-body .van-search__content{
		padding-left: 0px !important;
		margin-left: -.05rem;
	}

	/* border: .01rem solid var(--theme-primary-color) !important; */
</style>
<style scoped>
	

	.icontest {
		height: .7rem;
		width: 1.2rem;
		position: relative;
	}

	.icontest:before {
		background-color: var(--theme-color-line);
		bottom: .1rem;
		content: "";
		height: auto;
		position: absolute;
		right: 0;
		top: .1rem;
		width: .01rem;
	}

	.X5eogfqvbxD_ckZVQSll {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
		line-height: 1.28;
		padding: 0 .3rem .07rem 0;
		text-align: left
	}

	.login-register-from-space {
		padding-left: .3rem;
		padding-right: .3rem;
		box-sizing: border-box;
		padding-top: .2rem;
		width: 100%
	}

	.ZEcfoKF3Q_zq5EqaOdUy {
		color: var(--theme-secondary-color-error);
		display: flex;
		align-items: center;
		margin-left: .02rem;
		padding: 0;
		margin: 0;
		position: relative;
		padding-right: .09rem;
	}
	.ZEcfoKF3Q_zq5EqaOdUy i{
		position: absolute;
		/* top: -.01rem; */
	}

	.GaL3XJonIwzK4ZeJyCyq {
		background-color: #fff;
		background-image: none;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
		box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
		color: rgba(0, 0, 0, .65);
		cursor: pointer;
		display: inline-block;
		font-size: 14px;
		font-weight: 400;
		height: 32px;
		line-height: 1.499;
		padding: 0 15px;
		position: relative;
		text-align: center;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		-webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		white-space: nowrap
	}

	.GaL3XJonIwzK4ZeJyCyq {
		background-color: var(--theme-primary-color);
		border-color: var(--theme-primary-color);
		font-size: .24rem;
		height: .7rem;
		width: 100%;
		color: var(--theme-primary-font-color);
		text-shadow: none;
		box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
	}

	.chen_texbt {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
	}

	.ant-form-explain {
		color: var(--theme-secondary-color-error);
		font-size: .18rem;
		transition: color .3s cubic-bezier(.215, .61, .355, 1);
		line-height: 1.5 !important;
		position: relative;
		padding-left: .3rem;
		margin-bottom: .2rem;
	}

	.ant-form-explain::before {
		background-color: var(--theme-secondary-color-error);
		content: "";
		height: 0.18rem;
		margin-right: 0.1rem;
		visibility: visible;
		left: 0;
		top: 50%;
		margin-top: -0.09rem;
		width: 0.18rem;
		display: inline-block;
		position: absolute;
		background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
		background-size: 13.068rem 12.753rem;
		border-radius: 50%;
		background-position: -12.888rem -3.825rem;
	}

	.HwvTcjBqOBJV3S7RoZ7g {
		display: flex;
		align-items: center;
		min-height: .26rem;
		font-size: .22rem;
		margin-bottom: .2rem;
	}

	.HwvTcjBqOBJV3S7RoZ7g li {
		display: flex;
		width: 50%;
		color: var(--theme-text-color-darken);
	}

	.HwvTcjBqOBJV3S7RoZ7g svg {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: 9999.99rem;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		font-size: .3rem;
		min-width: .3rem;
		justify-content: center;
		line-height: 1;
		margin-right: .1rem;
	}
	.HwvTcjBqOBJV3S7RoZ7g .SujLpXQmwzyb0cHe24WB svg{
		background-color:var(--theme-primary-color) ;
		    color: var(--theme-primary-font-color);
	}

	.xuanzeactive {
		color: var(--theme-primary-color) !important;
	}

	.xuanzeactive svg {
		color: #000 !important;
		border: .01rem solid var(--theme-primary-color);
		background-color: var(--theme-primary-color);
	}

	.right-icon {
		height: .36rem !important;
		width: .36rem !important;
	}

	.rBX8yUXrZ3DzhJ4ZvZUD {
		display: flex;
		align-items: center;
		margin-bottom: .2rem;
	}

	.ptEr7ybENfgNXBPMnsRb .rBX8yUXrZ3DzhJ4ZvZUD span {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
		margin-right: .1rem;
		-webkit-transition: all .3s;
		transition: all .3s;
	}

	.ptEr7ybENfgNXBPMnsRb .rBX8yUXrZ3DzhJ4ZvZUD .VYe1C86O9HyF7KaCqIUg {
		background-color: var(--theme-color-line);
		border-radius: .12rem;
		height: .12rem;
		margin-right: .1rem;
		transition: all .3s;
		width: .8rem;
	}

	.ptEr7ybENfgNXBPMnsRb .rBX8yUXrZ3DzhJ4ZvZUD .QZ0QekXF9duW7ClyMSdQ {
		background-color: var(--theme-secondary-color-error) !important;
	}

	.inner-textbox {
		text-align: left !important;
		line-height: 1.5;
		margin-bottom: .2rem;
		font-size: 9.36px;
		color: var(--theme-secondary-color-finance);
	}

	/* .ant-select-selection__placeholder {
	    height: .3rem;
	    line-height: .3rem;
		font-size: .22rem;
		    font-weight: 400;
	    margin-top: -.15rem;
		    color: var(--theme-text-color-placeholder);
	} */
</style>
