import Vue from 'vue'
import moneyFun from "./moneyFun.js"

const forIn=(JSON)=>{
	for (let ar in JSON){
		Vue.prototype[ar]=JSON[ar]
	}
}
forIn(moneyFun)

